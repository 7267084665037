<div (keydown)="keydown($event)" *ngIf="data && data.status.Verfuegbarkeitsinformation" class="infobox">
  <h3>
    <ng-container *ngIf="data.artikel.ArtikelBezeichnung">
      {{data.artikel.ArtikelBezeichnung}}
    </ng-container>
    <ng-container *ngIf="!data.artikel.ArtikelBezeichnung">
      {{data.artikel.ArtikelName}}
    </ng-container>
  </h3>

  <div *ngFor="let i of data.status.Verfuegbarkeitsinformation">
    <div style="text-align: center">
      <svg-icon *ngIf="i.color=='green'" size="100" class="infoicon infoicon-green">success-bk</svg-icon>
      <svg-icon *ngIf="i.color=='orange'" size="100" class="infoicon infoicon-orange">rueckstaende</svg-icon>
      <svg-icon *ngIf="i.color=='orange2'" size="100" class="infoicon infoicon-orange2">success-bk</svg-icon>
      <svg-icon *ngIf="i.color=='red'" size="100" class="infoicon infoicon-red">abbrechen</svg-icon>
    </div>
    <div style="text-align: center; font-size: 50px">
      {{i.Menge}} ST - {{i.InfoText}}
    </div>
    <div *ngIf="i.Zusatzinformation && i.Zusatzinformation.length > 0">
      <hr>
      <table>
        <tr>
          <td class="label label2">Details:</td>
          <td class="info info2" colspan="2">
            <div *ngFor="let txt of i.Zusatzinformation">
              {{txt.text}}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
