import {MainMenuButtonConfig} from "../../../models/main-menu-button-config";
import {AppConfigService} from "../../../services/app.config.service";
import {AppMenuButtonService} from "../../../services/app.menu-button.service";

export const MainMenuConfig: MainMenuButtonConfig[] = [
  {
    id: 'base_menu',
    label: 'Basis',
    icon: 'dashboard',
    icon_color: undefined,
    class: 'tut_mainmenu_base_menu',
    class_fkt: undefined,
    route: undefined,
    roles: [],
    features: [],
    target: undefined,
    href: undefined,
    description: undefined,
    view: undefined,
    menu_name: 'Hauptmenü',
    children: [
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: 'dashboard',
        icon_color: undefined,
        class: 'tut_mainmenu_dashboard',
        class_fkt: undefined,
        route: '/dashboard',
        roles: [],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
      {
        id: 'calendar',
        label: 'Kalender',
        icon: 'kalender',
        icon_color: undefined,
        class: 'tut_mainmenu_calendar',
        class_fkt: undefined,
        route: '/calendar',
        roles: [],
        features: ['Calendar'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
      {
        id: 'downloads',
        label: 'Downloads',
        icon: 'downloads',
        icon_color: undefined,
        class: 'tut_mainmenu_downloads',
        class_fkt: undefined,
        route: '/downloads',
        roles: ['Download'],
        features: ['Downloads'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
      {
        id: 'customer-settings',
        label: 'Kunden-Einstellungen',
        icon: 'einstellungen',
        icon_color: undefined,
        class: undefined,
        class_fkt: (cfg: AppConfigService) => {
          return cfg.domainconfig.import_enabled ? 'tut_mainmenu_customer-settings' : 'tut_mainmenu_customer-settings2';
        },
        route: '/customer-settings',
        roles: ['Kunde'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
      {
        id: 'account',
        label: 'Account',
        icon: 'aktive-benutzer2',
        icon_color: undefined,
        class: 'tut_mainmenu_account',
        class_fkt: undefined,
        route: '/account',
        roles: ['User'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
    ],
    active: false,
  },
  {
    id: 'shop_menu',
    label: 'Shop',
    icon: 'shop',
    icon_color: undefined,
    class: 'tut_mainmenu_shop_menu',
    class_fkt: undefined,
    route: '/shop',
    roles: ['Auftrag'],
    features: [],
    target: undefined,
    href: undefined,
    description: undefined,
    view: undefined,
    menu_name: 'Hauptmenü',
    children: [
      {
        id: 'warenkorb',
        label: 'Warenkörbe',
        icon: 'warenkorb',
        icon_color: undefined,
        class: 'tut_shopmenu_warenkorb',
        class_fkt: undefined,
        route: '/shop/warenkoerbe',
        roles: ['Auftrag'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Shop',
        children: undefined,
        active: false,
      },
      {
        id: 'merklisten',
        label: 'Merklisten',
        icon: 'merkliste',
        icon_color: '#9e24ba',
        class: 'tut_shopmenu_merklisten',
        class_fkt: undefined,
        route: '/shop/merklisten',
        roles: ['Auftrag'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Shop',
        children: undefined,
        active: false,
      },
      {
        id: 'bestellungen',
        label: 'Web-Bestellungen',
        icon: 'bestellungen',
        icon_color: undefined,
        class: 'tut_shopmenu_bestellungen',
        class_fkt: undefined,
        route: '/shop/bestellungen',
        roles: ['Auftrag'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Shop',
        children: undefined,
        active: false,
      },
      (svc: AppMenuButtonService) => {
        return svc.shop_menu_view_buttons;
      }
    ],
    active: false,
  },
  {
    id: 'enrollment_menu',
    label: 'Meldungen',
    icon: 'meldungen',
    icon_color: undefined,
    class: 'tut_mainmenu_enrollment_menu',
    class_fkt: undefined,
    route: '/enrollment',
    roles: ['Preissenkung', 'Beipackanmeldung'],
    features: ['Preissenkung', 'Beipack'],
    target: undefined,
    href: undefined,
    description: undefined,
    view: undefined,
    menu_name: 'Hauptmenü',
    children: [
      {
        id: 'stocklevels',
        label: 'Bestandsmeldungen',
        icon: 'bestandsmeldung',
        icon_color: undefined,
        class: 'tut_enrollmenu_stocklevels',
        class_fkt: undefined,
        route: '/enrollment/stock-levels',
        roles: ['Bestandsmeldungen'],
        features: ['Bestandsmeldungen'],
        target: undefined,
        href: undefined,
        description: 'Preissenkungen / Austausch / Sonstiges',
        view: undefined,
        menu_name: 'Meldungen',
        children: undefined,
        active: false,
      },
      {
        id: 'enclosedshipping',
        label: 'Beipack',
        icon: 'beipack',
        icon_color: undefined,
        class: 'tut_enrollmenu_enclosedshipping',
        class_fkt: undefined,
        route: '/enrollment/enclosed-shipping',
        roles: ['Beipackanmeldung', 'Beipackanmeldung Dokumente an Betriebsstätte'],
        features: ['Beipack'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Meldungen',
        children: undefined,
        active: false,
      },
      {
        id: 'preorder',
        label: 'Vorbestellung',
        icon: 'vorbestellung',
        icon_color: undefined,
        class: 'tut_enrollmenu_preorder',
        class_fkt: undefined,
        route: '/enrollment/pre-order',
        roles: ['Vorbestellung'],
        features: ['Vorbestellung'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Meldungen',
        children: undefined,
        active: false,
      },
      {
        id: 'returns',
        label: 'Retourenanmeldung',
        icon: 'retourenanmeldung',
        icon_color: undefined,
        class: 'tut_enrollmenu_preorder',
        class_fkt: undefined,
        route: '/enrollment/return',
        roles: ['Apotheken'],
        features: ['ReturnRegistration'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Meldungen',
        children: undefined,
        active: false,
      },
    ],
    active: false,
  },

  {
    id: 'analyse_menu',
    label: 'Analyse',
    icon: 'analyse',
    icon_color: undefined,
    class: 'tut_mainmenu_analyse_menu',
    class_fkt: undefined,
    route: '/analytics',
    roles: [
      'Dokument Chargendokumentation',
      'Dokumentensuche',
      'Auftragsarchiv',
      'Rueckstaende',
      'Lieferapotheke',
      'Statistiken - Umsatz Gemischt',
      'Statistiken - Umsatz Nur Hochpreis',
      'Statistiken - Umsatz Ohne Hochpreis',
      'Statistiken - Umsatz Import',
      'Statistiken - Umsatz Magistral',
      'Statistiken - Umsatz Emballagen',
      'Statistiken - Umsatz Problemartikel'
    ],
    features: ['ChargenDokumentation', 'Dokumentensuche', 'Auftragsarchiv', 'Rueckstaende', 'Lieferapotheke', 'Statistiken'],
    target: undefined,
    href: undefined,
    description: undefined,
    view: undefined,
    menu_name: 'Hauptmenü',
    children: [
      {
        id: 'docsearch',
        label: 'Dokumentensuche',
        icon: 'dokumentensuche',
        icon_color: undefined,
        class: 'tut_menu_analytics_docsearch',
        class_fkt: undefined,
        route: '/analytics/documents',
        roles: [
          'Dokumente Lieferschein',
          'Dokumente DTP Lieferschein',
          'Dokumente Rechnung'
        ],
        features: ['Dokumentensuche'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Analyse',
        children: undefined,
        active: false,
      },
      {
        id: 'order-archive',
        label: 'Auftragsarchiv',
        icon: 'auftragsarchiv',
        icon_color: undefined,
        class: 'tut_menu_analytics_order-archive',
        class_fkt: undefined,
        route: '/analytics/order-archive',
        roles: ['Auftragsarchiv'],
        features: ['Auftragsarchiv'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Analyse',
        children: undefined,
        active: false,
      },
      {
        id: 'residues',
        label: 'Rückstände',
        icon: 'rueckstaende',
        icon_color: undefined,
        class: 'tut_menu_analytics_residues',
        class_fkt: undefined,
        route: '/analytics/residues',
        roles: ['Rueckstaende'],
        features: ['Rueckstaende'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Analyse',
        children: undefined,
        active: false,
      },
      {
        id: 'abrufvereinbarungen',
        label: 'Abrufvereinbarungen und Vorauszahlungen',
        icon: 'abrufer',
        icon_color: undefined,
        class: 'tut_menu_analytics_abrufvereinbarungen',
        class_fkt: undefined,
        route: '/analytics/abrufvereinbarungen',
        roles: ['Abrufvereinbarungen und Vorauszahlungen'],
        features: ['Abrufvereinbarungen'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Analyse',
        children: undefined,
        active: false,
      },
      {
        id: 'statistics',
        label: 'Statistiken',
        icon: 'statistiken',
        icon_color: undefined,
        class: 'tut_menu_analytics_statistics',
        class_fkt: undefined,
        route: '/analytics/statistics',
        roles: [
          'Statistiken - Umsatz Gemischt',
          'Statistiken - Umsatz Nur Hochpreis',
          'Statistiken - Umsatz Ohne Hochpreis',
          'Statistiken - Umsatz Import',
          'Statistiken - Umsatz Magistral',
          'Statistiken - Umsatz Emballagen',
          'Statistiken - Umsatz Problemartikel'
        ],
        features: ['Statistiken'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Analyse',
        children: undefined,
        active: false,
      },
      {
        id: 'batchdocu',
        label: 'Chargen Dokumentation',
        icon: 'chargendokumentation',
        icon_color: undefined,
        class: 'tut_menu_analytics_batchdocu',
        class_fkt: undefined,
        route: '/analytics/batch-documentation',
        roles: ['Dokument Chargendokumentation'],
        features: ['ChargenDokumentation'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Analyse',
        children: undefined,
        active: false,
      },
      {
        id: 'tourplan',
        label: 'Touren',
        icon: 'touren',
        icon_color: undefined,
        class: 'tut_mainmenu_tourplan',
        class_fkt: undefined,
        route: '/tour-browser',
        roles: ['Tourenbrowser'],
        features: ['Touren'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
      {
        id: 'callplan',
        label: 'Anrufplan',
        icon: 'anrufplan',
        icon_color: undefined,
        class: 'tut_mainmenu_callplan',
        class_fkt: undefined,
        route: '/call-plan',
        roles: ['Anrufplan'],
        features: ['CallPlan'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
      {
        id: 'delivery_pharmacy',
        label: 'Lieferapotheke',
        icon: 'lieferapotheke',
        icon_color: undefined,
        class: 'tut_menu_analytics_delivery_pharmacy',
        class_fkt: undefined,
        route: '/analytics/delivery-pharmacy',
        roles: ['Lieferapotheke'],
        features: ['Lieferapotheke'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Analyse',
        children: undefined,
        active: false,
      },
    ],
    active: false,
  },
  {
    id: 'others_menu',
    label: 'Sonstiges',
    icon: 'dashboard',
    icon_color: undefined,
    class: 'tut_mainmenu_base_menu',
    class_fkt: undefined,
    route: undefined,
    roles: [],
    features: [],
    target: undefined,
    href: undefined,
    description: undefined,
    view: undefined,
    menu_name: 'Hauptmenü',
    children: [
      {
        id: 'last-chance-articles',
        label: 'Noch verfügbare Artikel',
        icon: 'lastchance',
        icon_color: undefined,
        class: 'tut_mainmenu_last-chance-articles',
        class_fkt: undefined,
        route: '/last-chance-articles',
        roles: ['Noch verfügbare Artikel'],
        features: ['LastChanceArticles'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
      {
        id: 'cashbook',
        label: 'Kassabuch',
        icon: 'kassenbuch',
        icon_color: undefined,
        class: 'tut_mainmenu_cashbook',
        class_fkt: undefined,
        route: '/cashbook',
        roles: ['Kassabuch'],
        features: ['Kassabuch'],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
      {
        id: 'cashbook2',
        label: 'Kassabuch',
        icon: 'kassenbuch',
        icon_color: undefined,
        class: 'tut_mainmenu_cashbook',
        class_fkt: undefined,
        route: undefined,
        roles: ['Kassabuch'],
        features: ['KassabuchLink'],
        target: '_blank',
        href: 'https://kassabuch.jacoby-gm.at',
        description: 'kassabuch.jacoby-gm.at',
        view: undefined,
        menu_name: 'Hauptmenü',
        children: undefined,
        active: false,
      },
    ],
    active: false,
  },
  {
    id: 'admin',
    label: 'Admin',
    icon: 'admin',
    icon_color: undefined,
    class: 'tut_mainmenu_admin',
    class_fkt: undefined,
    route: '/admin',
    roles: ['Admin'],
    features: [],
    target: undefined,
    href: undefined,
    description: undefined,
    view: undefined,
    menu_name: 'Hauptmenü',
    children: [
      {
        id: 'admin_dashboard',
        label: 'Dashboard',
        icon: 'dashboard',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/dashboard',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_crontasks',
        label: 'Aufgabenplaner',
        icon: 'auftragsplaner',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/crontasks',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_sessions',
        label: 'Aktive Benutzer',
        icon: 'aktive-benutzer2',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/sessions',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_users',
        label: 'Benutzer',
        icon: 'mehrere-benutzer',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/users',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_domains',
        label: 'Domänenverwaltung',
        icon: 'domaenenverwaltung',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/domains',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_tokens',
        label: 'API-Tokens',
        icon: 'token',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/tokens',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_log',
        label: 'Logs',
        icon: 'log',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/log',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_settings',
        label: 'System - Einstellungen',
        icon: 'einstellungen',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/settings',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_files',
        label: 'Dateiverwaltung',
        icon: 'dateibrowser-ordner',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/files',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_conditions',
        label: 'Kundenbedingungen',
        icon: 'bedingungen',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/conditions',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_survey',
        label: 'Umfragen',
        icon: 'umfragen',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/survey',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
      {
        id: 'admin_locked_warenkoerbe',
        label: 'Gesperrte Warenkoerbe',
        icon: 'warenkorb',
        icon_color: undefined,
        class: undefined,
        class_fkt: undefined,
        route: '/admin/locked-warenkoerbe',
        roles: ['Admin'],
        features: [],
        target: undefined,
        href: undefined,
        description: undefined,
        view: undefined,
        menu_name: 'Admin',
        children: undefined,
        active: false,
      },
    ],
    active: false,
  },

];
