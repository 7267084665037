import {Injectable} from '@angular/core';
import {User} from '../models/user';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {AppMessageService} from './app.message.service';
import {AppEventService} from './app.event.service';
import {AppPageloaderService} from './app.pageloader.service';
import {DomainConfig} from '../models/domain-config';
import {AppLieferkundeService} from './app.lieferkunde.service';
import {AppPageTitleService} from './app.page-title.service';
import {SharedSvgIconService} from '../modules/shared/services/shared.svg-icon.service';
import {AppUserService} from "./app.user.service";

declare let navigator: any;

@Injectable({providedIn: 'root'})
export class AppBootloaderService {
  url_get_user = '/user/get';

  user: User;
  loggedin = false;
  done0 = false;
  done1 = false;
  done2 = false;
  done3 = false;

  token_tried = false;

  interval: any;
  load_user_started = false;
  protected loginToken = '';
  private isServer: boolean;

  constructor(private http: HttpClient, private cfg: AppConfigService,
              private esvc: AppEventService, private tsvc: AppPageTitleService,
              private msg: AppMessageService, private loader: AppPageloaderService,
              private lkndsvc: AppLieferkundeService, private isvc: SharedSvgIconService) {
    isvc.load();
  }

  checkOnline() {
    if (navigator !== undefined && navigator.connection !== undefined) {
      if (navigator.connection.type == 'none') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  loadConfig(exec_after: any) {
    const jsonFile = 'assets/config/config.json';
    this.http.get(jsonFile).subscribe({
      next: (response: Response) => {
        this.cfg.bootSVC(<any>response);
        this.done0 = true;
        if (exec_after) {
          exec_after();
        }
      }, error: (error: Error) => {
        console.error('Boot failed!');
      }
    });
  }

  loadConfig2(exec_after: any) {
    this.http.get(this.cfg.buildUrl('/domain/info')).subscribe({
      next: (cfg: DomainConfig) => {
        this.cfg.domainconfig = cfg;
        this.cfg.check_init_matomo();
        this.done3 = true;
        if (exec_after) {
          exec_after();
        }
      }, error: (error) => {
        console.error('Boot failed!');
      }
    });
  }

  try_boot(fkt_done) {
    let online = this.checkOnline();
    while (!online) {
      setTimeout(() => {
        online = this.checkOnline();
      }, 100);
    }
    let state1 = false;
    let state2 = false;
    let state3 = false;

    let donefkt = () => {
      if (state1 && state2 && state3) {
        fkt_done();
      }
    }

    this.loadConfig(() => {
      this.esvc.bootSVC();

      this.loadConfig2(() => {
        this.tsvc.resetTitle();
        state1 = true;
        donefkt();
      });

      this.loadUser(false, () => {
        state2 = true;
        donefkt();
      });

      this.lkndsvc.init().subscribe({
        next: (state) => {
          if (state) {
            state3 = true;
            this.loader.stopFullscreen();
          } else {
            this.loader.startFullscreen('Server derzeit nicht verfügbar');
            setTimeout(() => {
              this.try_boot(fkt_done);
            }, 2000);
          }
          state3 = true;
          donefkt();
        }, error: (e) => {
        }
      });
    });
  }

  boot(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.try_boot(() => {
        resolve(true);
      });
    });
  }

  loadUser(is_relogin = false, fkt_after: any = false) {
    return this.http.get(
      this.cfg.buildUrl(this.url_get_user)
    ).subscribe({
      next: (user: User) => {
        if (user) {
          Object.setPrototypeOf(user, User.prototype);
          this.user = user;
          this.loggedin = true;

          this.done1 = true;
          this.done2 = true;

          if (is_relogin) {
            this.msg.info('Willkommen zurück ' + this.user.benutzerandruckname);
          }

          if (fkt_after) {
            fkt_after();
          }

          this.esvc.addGuiEvent('loggedin');
        } else {
          this.done1 = true;
          this.done2 = true;

          if (fkt_after) {
            fkt_after();
          }

        }
      }, error: (error) => {
        this.done1 = true;
        this.done2 = true;

        if (fkt_after) {
          fkt_after();
        }
      }
    });
  }
}
