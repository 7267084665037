<div *ngIf="(aktionen|async)?.length > 0">
  <button (click)="showAktionen(artikel)" *ngIf="(viewcomp.visible_artikel_aktionen|async)?.artikel.ArtikelNummer != artikel.ArtikelNummer"
          mat-icon-button
          matTooltip="Anzeigen">
    <svg-icon>plus</svg-icon>
    mehr
  </button>

  <button (click)="hideAktionen()" *ngIf="(viewcomp.visible_artikel_aktionen|async)?.artikel.ArtikelNummer == artikel.ArtikelNummer"
          mat-icon-button
          matTooltip="Ausblenden">
    <svg-icon>minus</svg-icon>
    weniger
  </button>
</div>


