import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';


declare var window: {
  [key: string]: any;
  prototype: Window;
  new(): Window;
};

/**
 * Service for injecting the Matomo tracker in the application.
 *
 * @export
 */
@Injectable({providedIn: 'root'})
export class MatomoInjector {
  /**
   * Creates an instance of MatomoInjector.
   *
   * @memberof MatomoInjector
   */
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(platformId)) {
      window._paq = window._paq || [];
    } else {
    }
  }

  /**
   * Injects the Matomo tracker in the DOM.
   *
   * @param url: URL of the Matomo instance to connect to.
   * @param id : SiteId for this application/site.
   * @memberof MatomoInjector
   */
  init(url: string, id: number) {
    if (isPlatformBrowser(this.platformId)) {
      window._paq.push(['trackPageView']);
      window._paq.push(['enableLinkTracking']);
      window._paq.push(['setSecureCookie', true]);
      (() => {
        const u = url;
        window._paq.push(['setTrackerUrl', u + 'jgmtrack']);
        window._paq.push(['setSiteId', id.toString()]);
        setTimeout(() => {
          const d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0];
          g.type = 'text/javascript';
          g.async = true;
          g.defer = true;
          g.src = u + 'jgmtrack.js';
          s.parentNode.insertBefore(g, s);
        }, 1000);
      })();
    }
  }
}
