import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppViewService} from "../../../../services/app.view.service";
import {Subscription} from "rxjs";
import {View} from "../../../../models/view";

@Component({
  selector: 'app-dashboard-widgets-shop-views',
  templateUrl: 'dashboard.widgets.shop-views.component.html',
  styleUrls: ['dashboard.widgets.shop-views.component.scss'],
})
export class DashboardWidgetsShopViewsComponent implements OnInit, OnDestroy {
  static id = 'DashboardWidgetsShopViewsComponent';
  static label = 'Shops';
  static description = 'Zeigt die verfügbaren Shop-Ansichten an.';
  static cols = 6;
  static rows = 6;

  vsub: Subscription;

  views: View[] = [];

  reload_done_once = false;

  constructor(private svc: AppViewService) {
  }

  ngOnInit(): void {
    this.vsub = this.svc.menu_views$.subscribe(vs => {
      this.views = vs;
      if (vs && vs.length == 0 || !vs) {
        if (!this.reload_done_once) {
          this.reload_done_once = true;
          this.svc.load();
        } else {
          setTimeout(() => {
            this.svc.load();
          }, 1000);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.vsub instanceof Subscription) {
      this.vsub.unsubscribe();
    }
  }





}
