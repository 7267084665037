import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Location} from '@angular/common';
import {AppConfigService} from "./app.config.service";

@Injectable({providedIn: 'root'})
export class AppNavbarService {
  label: BehaviorSubject<string> = new BehaviorSubject<string>('');
  label$ = this.label.asObservable();

  bg_color: BehaviorSubject<string> = new BehaviorSubject<string>('');
  bg_color$ = this.bg_color.asObservable();

  bg_link_color: BehaviorSubject<string> = new BehaviorSubject<string>('');
  bg_link_color$ = this.bg_link_color.asObservable();

  inversed_logo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  inversed_logo$ = this.inversed_logo.asObservable();

  font_color: BehaviorSubject<string> = new BehaviorSubject<string>('');
  font_color$ = this.font_color.asObservable();

  on_back_click: BehaviorSubject<any> = new BehaviorSubject(false);
  on_back_click$ = this.on_back_click.asObservable();

  show_back: BehaviorSubject<boolean> = new BehaviorSubject(false);
  show_back$ = this.show_back.asObservable();

  constructor(private location: Location, private cfg: AppConfigService) {
  }

  setFontColor(color) {
    this.font_color.next(color);
  }

  setBGColor(color) {
    this.bg_color.next(color);
  }

  setBGLinkColor(color) {
    this.bg_link_color.next(color);
  }

  setInversedLogo(state) {
    this.inversed_logo.next(state);
  }

  resetColors() {
    let defaultBGColor = this.cfg.domainconfig.navBarBGColor ?? '';
    let defaultBGLinkColor = this.cfg.domainconfig.navBarBGLinkColor ?? '';
    let defaultFontColor = this.cfg.domainconfig.navBarFontColor ?? '';
    this.setBGColor(defaultBGColor);
    this.setBGLinkColor(defaultBGLinkColor);
    this.setFontColor(defaultFontColor);
    this.inversed_logo.next(false);
  }

  realBack() {
    this.location.back();
  }

  showBack(fkt: any = null) {
    if (fkt) {
      this.on_back_click.next(fkt);
    } else {
      this.on_back_click.next(() => {
        this.realBack();
      });
    }
    this.show_back.next(true);
  }

  setLabel(label: string) {
    this.label.next(label);
  }

  triggerBack() {
    this.back();
  }

  hideBack() {
    this.show_back.next(false);
  }

  clear() {
    this.label.next('');
    this.hideBack();
  }

  private back() {
    this.hideBack();
    let fkt = this.on_back_click.value;
    if (fkt) {
      fkt();
    } else {
      this.realBack();
    }
  }
}
