import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AppEventService} from './services/app.event.service';
import {NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AppPageTitleService} from './services/app.page-title.service';
import {AppPageloaderService} from './services/app.pageloader.service';
import {Subscription} from 'rxjs';
import {MatomoTracker} from './modules/matamo/service/matomo-tracker.service';
import {AppConfigService} from './services/app.config.service';
import {AppUpdateService} from './services/app.update.service';
import {AppPrivacyService} from './services/app.privacy.service';
import {AppUserService} from './services/app.user.service';
import {User} from './models/user';
import * as Sentry from '@sentry/angular-ivy';
import {EventHint} from '@sentry/angular-ivy';
import {SwPush} from "@angular/service-worker";
import {Logger} from "./services/app.logger.service";

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'frontend';

  rsub: Subscription;
  lsub: Subscription;
  usub: Subscription;
  usubLoggedin: Subscription;
  privacy_sub: Subscription;

  user: User;

  @HostListener('window:resize', ['$event'])
  calcAppSize() {
    const doc = document.documentElement;

    doc.style.setProperty('--app-height', `calc(${window.innerHeight}px - env(safe-area-inset-top))`);
    doc.style.setProperty('--app-width', `${window.innerWidth}px`);
  }

  constructor(private esvc: AppEventService, private router: Router, private tracker: MatomoTracker, private psvc: AppPrivacyService,
              private cfg: AppConfigService, private tsvc: AppPageTitleService, private loader: AppPageloaderService,
              private swPush: SwPush, private updsvc: AppUpdateService, private usvc: AppUserService) {
  }

  initSentryUser() {
    if (this.psvc.settings.sentry_userid && this.user) {
      Sentry.setUser({
        id: this.user.id,
        email: this.user.email,
        username: this.user.benutzerandruckname
      });
    } else {
      Sentry.setUser(null);
    }
  }

  initSentry() {
    if ((this.psvc.settings.sentry || this.psvc.settings.sentry_performance) && !this.cfg.isDebug() ) {
      Logger.info('AppComponent', "initSentry", "init!");
      Sentry.init({
        environment: window.location.host,
        dsn: 'https://160b503316d249569982ec3185d958ca@o982183.ingest.sentry.io/5937043',
        tunnel: '/api/sntunnel',
        integrations: [
          new Sentry.Replay(),
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        release: 'jgmportal@' + this.cfg.getVersion(),
        ignoreErrors: [
          'Non-Error exception captured',
          'UnknownError'
        ],

        // @ts-ignore
        beforeSend: function (event: Event, hint?: EventHint): PromiseLike<Event | null> | Event | null {
          if (window.SENTRY_DEBUG && window.SENTRY_DEBUG == true) {
            return null;
          } else {
            return event;
          }
        },

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: this.psvc.settings.sentry_performance ? 0.2 : 0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
      });

      this.initSentryUser();
    }

  }

  subscribeToNotifications() {
    if (this.swPush.isEnabled) {
      this.swPush.requestSubscription({
        serverPublicKey: this.cfg.VAPID_PUBLIC_KEY
      })
        .then(sub => this.usvc.addDevice(sub).subscribe())
        .catch(err => console.error("Could not subscribe to notifications", err));

    }
  }


  initMatomo() {
    if (this.cfg.matomo_enabled) {
      if (this.psvc.settings.matomo_userid || this.psvc.settings.matomo_pageview) {
        this.tracker.enableCrossDomainLinking();
        this.tracker.enableHeartBeatTimer(5);
      }
    }
  }

  trackRouterMatomo() {
    if (this.cfg.matomo_enabled) {
      if (this.psvc.settings.matomo_pageview) {
        setTimeout(() => {
          this.tracker.setCustomUrl(this.router.url);
          this.tracker.setReferrerUrl(this.router.url);
          this.tracker.trackPageView();
          this.tracker.trackAllContentImpressions();
        }, 100);
      }
    }
  }

  trackImpressionsMatomo() {
    if (this.cfg.matomo_enabled) {
      if (this.psvc.settings.matomo_pageview) {
        this.tracker.trackAllContentImpressions();
      }
    }
  }

  initMatomoUser(user: User) {
    if (this.cfg.matomo_enabled) {
      if (this.psvc.settings.matomo_userid && user) {
        this.tracker.setUserId(user.id);
      }
    }
  }

  ngOnInit(): void {
    this.calcAppSize();
    this.privacy_sub = this.psvc.hasSettings$.subscribe(state => {
      this.initMatomo();
      this.initMatomoUser(this.user);
      this.trackImpressionsMatomo();
      this.trackRouterMatomo();
      this.initSentry();
    });
    this.usub = this.usvc.user$.subscribe(u => {
      this.user = u;
      this.initMatomoUser(u);
      this.initSentryUser();
    });
    this.updsvc.check();

    this.usubLoggedin = this.usvc.isLoggedin$.subscribe(isLoggedin => {
      if (isLoggedin) {
        this.subscribeToNotifications();
      }
    })

    this.rsub = this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.loader.stopAll();
        this.loader.start('Navigation');

      } else if (e instanceof NavigationEnd) {
        this.esvc.addGuiEvent('NavigationEnd');
        this.loader.stop('Navigation');

        this.trackRouterMatomo();

      } else if (e instanceof NavigationCancel) {
        this.loader.stop('Navigation');
      }
    });

    this.lsub = this.loader.loading$.subscribe(s => {
      if (!s) {
        this.trackImpressionsMatomo();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.rsub instanceof Subscription) {
      this.rsub.unsubscribe();
    }
    if (this.lsub instanceof Subscription) {
      this.lsub.unsubscribe();
    }
    if (this.privacy_sub instanceof Subscription) {
      this.privacy_sub.unsubscribe();
    }
    if (this.usub instanceof Subscription) {
      this.usub.unsubscribe();
    }
    if (this.usubLoggedin instanceof Subscription) {
      this.usubLoggedin.unsubscribe();
    }
  }

  bodyClick() {
    this.esvc.addGuiEvent('app_body_click');

    this.trackImpressionsMatomo();
  }
}
