import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {Artikel} from '../../../../models/artikel';
import {AppViewService} from '../../../../services/app.view.service';
import {View} from '../../../../models/view';
import {BehaviorSubject} from 'rxjs';
import {ArtikelAktion} from '../../../../models/artikel-aktion';
import {ShopViewComponent} from '../view/shop.view.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'artikel-aktionen-button',
  templateUrl: 'shop.artikel-aktionen-button.component.html',
  styleUrls: ['shop.artikel-aktionen-button.component.scss']
})
export class ShopArtikelAktionenButtonComponent implements OnChanges, OnInit {
  @Input() artikel: Artikel;
  @Input() view: View;
  @Input() viewcomp: ShopViewComponent;

  aktionen: BehaviorSubject<ArtikelAktion[]> = new BehaviorSubject<ArtikelAktion[]>([]);

  constructor(private svc: AppViewService, private cd: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  showAktionen(artikel: Artikel) {
    this.viewcomp.showAktion(artikel, this.aktionen.value);
  }

  hideAktionen() {
    this.viewcomp.hideAktionen();
  }

  ngOnInit(): void {
    if (this.artikel.ArtikelHatAktion) {
      setTimeout(() => {
        this.svc.getAktionenSlug(this.view.slug, this.artikel).subscribe(a => {
          this.aktionen.next(a);
          this.cd.detectChanges();
        });
      }, 1500);
    }
  }


}
