import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppMobileService} from '../../services/app.mobile.service';
import {AppNavbarService} from '../../services/app.navbar.service';
import {AppConfigService} from '../../services/app.config.service';
import {AppPrintService} from '../../services/app.print.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {AppLieferkundeService} from "../../services/app.lieferkunde.service";
import {takeUntil} from "rxjs/operators";
import {DomainConfig} from "../../models/domain-config";
import {Lieferkunde} from "../../models/lieferkunde";
import {AppService} from "../../services/app.service";

@Component({
  selector: 'app-navbar',
  templateUrl: 'app.nav-bar.component.html',
  styleUrls: ['app.nav-bar.component.scss']
})
export class AppNavBarComponent implements OnInit, OnDestroy {
  active2 = new BehaviorSubject<boolean>(false);

  isMobile = new BehaviorSubject(false);

  domainConfig: DomainConfig;

  nbLabel = new BehaviorSubject(null);

  currentLieferkunde = new BehaviorSubject<Lieferkunde>(null);

  isPrinting = new BehaviorSubject(false);
  nbBgColor = new BehaviorSubject(null);
  nbBgLinkColor = new BehaviorSubject(null);
  nbInversedLogo = new BehaviorSubject(false);
  nbFontColor = new BehaviorSubject(null);

  _onDestroy = new Subject();

  hasBack = new BehaviorSubject<boolean>(false);

  constructor(private msvc: AppMobileService, public nbsvc: AppNavbarService, public cfg: AppConfigService,
              private psvc: AppPrintService, private lksvc: AppLieferkundeService, private app: AppService) {
  }

  back() {
    this.app.sectionTitle.back();
  }

  ngOnInit(): void {
    this.app.sectionTitleChange.pipe(takeUntil(this._onDestroy)).subscribe(st => {
      this.hasBack.next(!!(this.app.sectionTitle.hasBack || this.app.sectionTitle.backUrl));
    })
    this.domainConfig = this.cfg.domainconfig;
    this.nbsvc.resetColors();
    this.msvc.isMobile()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(m => this.isMobile.next(m));

    this.nbsvc.label$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(l => this.nbLabel.next(l));

    this.nbsvc.bg_color$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(c => this.nbBgColor.next(c));

    this.nbsvc.bg_link_color$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(c => this.nbBgLinkColor.next(c));

    this.nbsvc.font_color$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(c => this.nbFontColor.next(c));

    this.nbsvc.inversed_logo$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(c => this.nbInversedLogo.next(c));

    this.lksvc.current_lieferkunde$.pipe(takeUntil(this._onDestroy))
      .subscribe(lk => this.currentLieferkunde.next(lk));

    this.psvc.printing$
      .pipe(takeUntil(this._onDestroy))
      .subscribe(p => this.isPrinting.next(p));
  }


  showMenu2() {
    if (this.active2.value) {
      this.active2.next(false);
    } else {
      this.active2.next(true);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
}
