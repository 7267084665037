import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {GlobalSearchResult} from "../../models/global-search-result";
import {AppGlobalSearchService} from "../../services/app.global-search.service";
import {AppPageloaderService} from "../../services/app.pageloader.service";
import {GlobalSearchResultEntry} from "../../models/global-search-result-entry";
import {Router} from "@angular/router";
import {animate, group, stagger, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'global-search-box',
  templateUrl: 'app.global-search-box.component.html',
  styleUrls: ['app.global-search-box.component.scss']
})
export class AppGlobalSearchBoxComponent implements OnInit, OnDestroy {

  widthPx;


  widthPxMin = 80;
  widthPxMax = 250;

  focus = false;
  searchValue: string;

  has_search = false;

  searchReady = false;

  focusOutTimeout: any;

  showResults = new BehaviorSubject<boolean>(false);

  results = new BehaviorSubject<GlobalSearchResult[]>(null);

  @ViewChild('globalSearchInput') globalSearchInput: ElementRef;

  constructor(private svc: AppGlobalSearchService, private loader: AppPageloaderService,
              private router: Router) {
  }

  clickResultEntry(result: GlobalSearchResultEntry) {
    if (result.url) {
      if (result.url.startsWith('/')) {
        this.router.navigate([result.url], {queryParams: result.params});
      } else {
        window.location.href = result.url;
      }
    }
  }

  keyUP() {
    if (this.searchValue) {
      this.has_search = true;

      this.searchReady = this.searchValue.length >= 3;

    } else {
      this.has_search = false;
    }
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.focusOut();
  }

  reset($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.searchValue = "";
    this.results.next(null);
    this.showResults.next(false);
    this.keyUP();



    if (this.globalSearchInput) {
      this.globalSearchInput.nativeElement.focus();
    }
  }

  focusIn() {
    if (this.focusOutTimeout) {
      clearTimeout(this.focusOutTimeout);
    }
    this.focus = true;
    this.widthPx = this.widthPxMax;
    if (this.results.value) {
      this.showResults.next(true);
    }
  }

  focusOut() {
    this.focusOutTimeout = setTimeout(() => {
      this.focus = false;
      this.widthPx = this.widthPxMin;
      this.showResults.next(false);
    }, 400);
  }

  search() {
    if (this.searchReady) {
      this.searchReady = false;
      this.showResults.next(true);
      let l = this.loader.start();

      this.svc.search(this.searchValue).subscribe(res => {
        this.results.next(res);
        l.stop();
      });
    }
  }
}
