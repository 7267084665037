import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Lieferkunde} from '../models/lieferkunde';
import {AppEventService} from './app.event.service';
import {map} from 'rxjs/operators';
import {AppPageloaderService} from './app.pageloader.service';

@Injectable({providedIn: 'root'})
export class AppLieferkundeService {
  current_lieferkunde = new BehaviorSubject<Lieferkunde>(null);
  current_lieferkunde$ = this.current_lieferkunde.asObservable();

  current_differential_lieferkunde = new BehaviorSubject<Lieferkunde>(null);
  current_differential_lieferkunde$: Observable<Lieferkunde> = this.current_differential_lieferkunde.asObservable();

  lieferkundenMap = new BehaviorSubject<{ }>(null);

  init_pending = false;

  initMapDone = false;

  //FIRSTTIME = LOGIN
  isLogin = true;

  constructor(private http: HttpClient, private cfg: AppConfigService, private esvc: AppEventService,
              private loader: AppPageloaderService) {
    esvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\LieferkundeEngine\\LieferkundeChangedEvent') {
        if (!this.current_lieferkunde.value || e.data.new_lieferkunde_nr != this.current_lieferkunde.value.LieferkundeNr) {
          this.init().subscribe();
        }

      } else if (e.name == 'App\\Events\\LieferkundeEngine\\DifferentialLieferkundeChangedEvent') {
        if (!this.current_lieferkunde.value || e.data.new_lieferkunde_nr != this.current_differential_lieferkunde.value.LieferkundeNr) {
          this.init().subscribe();
        }

      } else if (e.name == 'gui.user.logoff' || e.name == 'gui.impersonate_start' || e.name == 'gui.depersonate_start') {
        this.current_lieferkunde.next(null);
      }
    });


  }

  initMap() {
    if (!this.initMapDone) {
      this.initMapDone = true;
      this.getMap().subscribe(m => this.lieferkundenMap.next(m));
    }

  }

  clear() {
    this.current_lieferkunde.next(null);
  }

  reload() {
    this.init(true).subscribe();
  }

  getMap() {
    return this.http.get<any>(
      this.cfg.buildUrl('/lieferkunde/getMap'),
    );
  }

  getAll() {
    return this.http.get<Lieferkunde[]>(
      this.cfg.buildUrl('/lieferkunde/getAll'),
    );
  }

  warmUp() {
    return this.http.get<Lieferkunde[]>(
      this.cfg.buildUrl('/lieferkunde/warmup'),
    );
  }

  search(search = null, force_reload = false, limit = null): Observable<Lieferkunde[]> {
    const l = this.loader.start();
    if (force_reload) {
      return this.http.post<Lieferkunde[]>(
        this.cfg.buildUrl('/lieferkunde/search'),
        {
          reload: true,
          search: search,
          limit: limit
        }
      ).pipe(map(lks => {

        l.stop();
        return lks;
      }));
    } else {
      return this.http.post<Lieferkunde[]>(
        this.cfg.buildUrl('/lieferkunde/search'),
        {
          search: search,
          limit: limit
        }
      ).pipe(map(lks => {
        l.stop();
        return lks;
      }));
    }
  }

  getMailAdresses(lknr): Observable<string[]> {
    return this.http.get<string[]>(
      this.cfg.buildUrl('/lieferkunde/getmailadresses/' + lknr)
    );
  }

  getLastOrderMailConfirmationAdresses(lknr): Observable<string[]> {
    return this.http.get<string[]>(
      this.cfg.buildUrl('/lieferkunde/getmailconfirmation/' + lknr)
    );
  }

  init(force_reload = false, ignore_pending = false): Observable<boolean> {
    if (!this.init_pending || ignore_pending) {
      this.isLogin = true;
      this.lieferkundenMap.next(null);
      this.initMapDone = false;
      let init_done1 = false;
      let init_done2 = false;

      this.init_pending = true;
      let error = false;

      this.http.get<Lieferkunde>(
        this.cfg.buildUrl('/lieferkunde/get'),
      ).subscribe({
        next: (lk) => {
          if (lk) {
            Object.setPrototypeOf(lk, Lieferkunde.prototype);
            this.isLogin = false;
          }
          this.current_lieferkunde.next(lk);
          init_done1 = true;
        },
        error: (e) => {
          init_done1 = true;
          error = true;
        }
      });

      this.http.get<Lieferkunde>(
        this.cfg.buildUrl('/lieferkunde/getdiff'),
      ).subscribe({
        next: (lk) => {
          if (lk) {
            Object.setPrototypeOf(lk, Lieferkunde.prototype);
            this.isLogin = false;
          }
          this.current_differential_lieferkunde.next(lk);
          init_done2 = true;
        },
        error: (e) => {
          init_done2 = true;
          error = true;
        }
      });

      return new Observable((o) => {
        const intv = setInterval(() => {
          if (init_done1 && init_done2) {
            setTimeout(() => {
              if (error) {
                o.next(false);
              } else {
                o.next(true);
              }
              this.init_pending = false;
              o.complete();
            }, 100);
            clearInterval(intv);
          }
        }, 50);
      });
    } else {
      return new Observable((o) => {
        const intv = setInterval(() => {
          if (!this.init_pending) {
            setTimeout(() => {
              o.next(true);
              o.complete();
            }, 100);
            clearInterval(intv);
          }
        }, 50);
      });
    }
  }

  resetDifferentialLieferkunde(): Observable<boolean> {
    return new Observable(obs => {
      this.http.post<boolean>(
        this.cfg.buildUrl('/lieferkunde/resetdiff'),
        {}
      ).subscribe(r => {
        this.init().subscribe(r => {
          obs.next();
          obs.complete();
        })
      })
    })
  }

  set(lk: Lieferkunde, differential = false): Observable<boolean> {
    return new Observable(obs => {
      if (differential) {
        this.http.post<boolean>(
          this.cfg.buildUrl('/lieferkunde/setdiff/' + lk.LieferkundeNr.toString()),
          {}
        ).subscribe(r => {
          this.init().subscribe(r => {
            obs.next();
            obs.complete();
          });
        });
      } else {
        this.http.post<boolean>(
          this.cfg.buildUrl('/lieferkunde/set/' + lk.LieferkundeNr.toString()),
          {}
        ).subscribe(r => {
          this.init().subscribe(r => {
            obs.next();
            obs.complete();
          });
        });
      }
    });
  }

}
