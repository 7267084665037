import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {
  ShopViewFieldValueRendererComponent
} from './components/view-field-value-renderer/shop.view-field-value-renderer.component';
import {
  ShopWarenkorbEntryEditFieldsComponent
} from './components/warenkorb-entry-edit-fields/shop.warenkorb-entry-edit-fields.component';
import {
  ShopVerfuegbarkeitsInfoButtonComponent
} from './components/verfuegbarkeits-info-button/shop.verfuegbarkeits-info-button.component';
import {ShopArtikelStatusIconComponent} from './components/artikel-status-icon/shop.artikel-status-icon.component';
import {
  ShopArtikelAktionenButtonComponent
} from './components/artikel-aktionen-button/shop.artikel-aktionen-button.component';
import {ShopArtikelNoteComponent} from './components/artikel-note/shop.artikel-note.component';
import {ShopViewComponent} from './components/view/shop.view.component';
import {RouterModule} from '@angular/router';
import {
  ShopWarenkorbImportTickerComponent
} from './components/warenkorb-import-ticker/shop.warenkorb-import-ticker.component';
import {ShopViewFilterComponent} from './components/view-filter/shop.view-filter.component';
import {ShopViewResultTableCompontent} from './components/view-result-table/shop.view-result-table.compontent';
import {ShopViewResultBoxCompontent} from './components/view-result-box/shop.view-result-box.compontent';
import {ShopViewFilterMenuComponent} from './components/view-filter-menu/shop.view-filter-menu.component';
import {ShopViewActionComponent} from './components/view-action/shop.view-action.component';
import {
  ShopArtikelAktionenDetailsComponent
} from './components/artikel-aktionen-details/shop.artikel-aktionen-details.component';
import {ShopWarenkorbButtonComponent} from './components/warenkorb-button/shop.warenkorb-button.component';
import {ShopMerklistenButtonComponent} from './components/merklisten-button/shop.merklisten-button.component';
import {
  ShopWarenkorbActionInfoComponent
} from './components/warenkorb-action-info/shop.warenkorb-action-info.component';
import {
  ShopMerklisteActionInfoComponent
} from './components/merkliste-action-info/shop.merkliste-action-info.component';
import {
  ShopDialogsVertreterAddToWarenkorbComponent
} from './dialogs/vertreter-add-to-warenkorb/shop.dialogs.vertreter-add-to-warenkorb.component';
import {
  ShopWarenkorbBestellblockButtonComponent
} from './components/warenkorb-bestellblock-button/shop.warenkorb-bestellblock-button.component';
import {ShopWarenkorbBoxComponent} from './components/warenkorb-box/shop.warenkorb-box.component';
import {ShopWarenkorbTableComponent} from './components/warenkorb-table/shop.warenkorb-table.component';
import {ShopAjaxWarenkorbComponent} from './components/ajax-warenkorb/shop.ajax-warenkorb.component';
import {ShopMerklisteMenuComponent} from './components/merkliste-menu/shop.merkliste-menu.component';
import {ShopWarenkorbMenuComponent} from './components/warenkorb-menu/shop.warenkorb-menu.component';
import {
  ShopViewFilterBottomsheetComponent
} from './components/view-filter-bottomsheet/shop.view-filter-bottomsheet.component';
import {ShopMerklisteTableComponent} from './components/merkliste-table/shop.merkliste-table.component';
import {ShopBonusPunkteIconsComponent} from "./components/bonus-punkte-icons/shop.bonus-punkte-icons.component";
import {
  ShopDialogsBonusPunkteDetailsComponent
} from "./dialogs/bonus-punkte-details/shop.dialogs.bonus-punkte-details.component";
import {ShopViewArticleDetailsComponent} from "./components/view-article-details/shop.view-article-details.component";
import {
  ShopViewArticleDetailsInfoComponent
} from "./components/view-article-details-info/shop.view-article-details-info.component";
import {StatisticsComponentsModule} from "../statistics/statistics.components.module";
import {PdfJsViewerModule} from "ng2-pdfjs-viewer";
import {
  ShopCustomerSpecialPriceEditButtonComponent
} from "./components/customer-special-price-edit-button/shop.customer-special-price-edit-button.component";
import {
  ShopDialogsCustomerSpecialPriceListComponent
} from "./dialogs/customer-special-price-list/shop.dialogs.customer-special-price-list.component";

@NgModule({
  declarations: [
    ShopViewFieldValueRendererComponent,
    ShopWarenkorbEntryEditFieldsComponent,
    ShopVerfuegbarkeitsInfoButtonComponent,
    ShopArtikelStatusIconComponent,
    ShopArtikelAktionenButtonComponent,
    ShopArtikelNoteComponent,
    ShopViewComponent,
    ShopWarenkorbImportTickerComponent,
    ShopViewFilterComponent,
    ShopViewFilterMenuComponent,
    ShopViewResultTableCompontent,
    ShopViewResultBoxCompontent,
    ShopViewActionComponent,
    ShopArtikelAktionenDetailsComponent,
    ShopWarenkorbButtonComponent,
    ShopMerklistenButtonComponent,
    ShopWarenkorbActionInfoComponent,
    ShopMerklisteActionInfoComponent,
    ShopDialogsVertreterAddToWarenkorbComponent,
    ShopWarenkorbBestellblockButtonComponent,
    ShopWarenkorbBoxComponent,
    ShopWarenkorbTableComponent,
    ShopAjaxWarenkorbComponent,
    ShopMerklisteMenuComponent,
    ShopWarenkorbMenuComponent,
    ShopViewFilterBottomsheetComponent,
    ShopMerklisteTableComponent,
    ShopBonusPunkteIconsComponent,
    ShopDialogsBonusPunkteDetailsComponent,
    ShopViewArticleDetailsComponent,
    ShopViewArticleDetailsInfoComponent,
    ShopCustomerSpecialPriceEditButtonComponent,
    ShopDialogsCustomerSpecialPriceListComponent,
  ],
  exports: [
    ShopViewFilterBottomsheetComponent,
    ShopWarenkorbMenuComponent,
    ShopMerklisteMenuComponent,
    ShopWarenkorbBoxComponent,
    ShopWarenkorbTableComponent,
    ShopViewResultTableCompontent,
    ShopViewResultBoxCompontent,
    ShopViewFilterComponent,
    ShopWarenkorbImportTickerComponent,
    ShopViewFieldValueRendererComponent,
    ShopWarenkorbEntryEditFieldsComponent,
    ShopVerfuegbarkeitsInfoButtonComponent,
    ShopArtikelStatusIconComponent,
    ShopArtikelAktionenButtonComponent,
    ShopArtikelNoteComponent,
    ShopViewComponent,
    ShopViewActionComponent,
    ShopWarenkorbButtonComponent,
    ShopMerklisteTableComponent,
    ShopArtikelAktionenDetailsComponent,
    ShopViewArticleDetailsComponent,
    ShopViewArticleDetailsInfoComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([]),
    StatisticsComponentsModule,
    PdfJsViewerModule,
  ],
  providers: []
})
export class ShopCompontentsModule {
}
