import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input, OnDestroy, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {SectionTitleButton} from '../../models/section-title-button';
import {AppBackService} from '../../../../services/app.back.service';
import {AppMobileService} from '../../../../services/app.mobile.service';
import {BehaviorSubject, Subject} from "rxjs";
import {AppService} from "../../../../services/app.service";
import {takeUntil} from "rxjs/operators";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'section-title',
  templateUrl: 'shared.section-title.component.html',
  styleUrls: ['shared.section-title.component.scss']
})
export class SharedSectionTitleComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() label: string;
  @Input() icon: string;
  @Input() buttons_right: SectionTitleButton[];
  @Input() buttons_left: SectionTitleButton[];
  @Input() hasBack = false;
  @Input() backUrl: string = null;
  @Input() disableBackSvc = false;
  @Input() left_full_width = false;
  @Output() onBackClick = new EventEmitter();

  @ViewChild('portal_section_title') portal_section_title: ElementRef;

  @ViewChild('contentMiddle') contentMiddle: ElementRef;
  @ViewChild('contentRight') contentRight: ElementRef;

  hidden = new BehaviorSubject<boolean>(true);
  showMore = new BehaviorSubject<boolean>(false);
  backAvailable = new BehaviorSubject<boolean>(false);

  lastHeight = 0;

  initChecked = false;

  _onDestroy = new Subject();

  isMobile = false;

  constructor(private backsvc: AppBackService, public msvc: AppMobileService, private app: AppService) {
  }

  ngOnInit(): void {
    this.app.registerSectionTitleComponent(this);

    this.backsvc.backAvailable.pipe(takeUntil(this._onDestroy)).subscribe(ba => {
      this.backAvailable.next(ba);
    });

    this.msvc.isMobile().pipe(takeUntil(this._onDestroy)).subscribe(m => {
      this.isMobile = m;
    });
  }

  toggle() {
    this.hidden.next(!this.hidden.value);
  }

  ngAfterViewChecked(): void {
    let height = this.portal_section_title.nativeElement.clientHeight;
    if (height != this.lastHeight) {
      this.lastHeight = height;
      const doc = document.documentElement;
      doc.style.setProperty('--app-section-title-height', `${height}px`);
    }

    if (!this.initChecked && this.isMobile) {
      this.initChecked = true;

      let countElements = this.contentMiddle.nativeElement.querySelectorAll('input,button,a').length +
        this.contentRight.nativeElement.querySelectorAll('input,button,a').length;
      setTimeout(() => {
        if (countElements > 0) {
          this.showMore.next(true);
        } else {
          this.showMore.next(false);
        }
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();

    const doc = document.documentElement;
    doc.style.setProperty('--app-section-title-height', `0px`);
  }

  back() {
    this.onBackClick.emit();
    if (!this.disableBackSvc) {
      this.backsvc.back(this.backUrl);
    }
  }
}
