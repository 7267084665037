<mat-toolbar [class.printhidden]="(isPrinting|async)" class="app-header mat-elevation-z1" color="primary"
             [style.background-color]="(nbBgColor|async) ? (nbBgColor|async)+' !important': ''"
             [style.color]="(nbFontColor|async) ? (nbFontColor|async)+' !important' : ''"
             [class.mobile]="isMobile|async"
             [class.desktop]="!(isMobile|async)">
  <table>
    <tr>
      <td class="td1">
        <a class="logo" routerLink="/" [class.hidden]="((isMobile|async) && (hasBack|async))">
          <img alt="Logo" src="{{domainConfig.logofilename}}" [class.hidden]="(nbInversedLogo|async)">
          <img alt="Logo" src="{{domainConfig.logofilenameInversed}}" [class.hidden]="!(nbInversedLogo|async)">
        </a>

        <div *ngIf="(isMobile|async) && (hasBack|async)" class="backButtonBox">
          <button (click)="nbsvc.triggerBack()"  class="navbarbackbutton tut_dashboard_button" mat-button
                  matTooltip="Zurück">
            <svg-icon>zurueck</svg-icon>
          </button>
        </div>

        <button routerLink="/dashboard" routerLinkActive="active" class="mainmenubutton tut_dashboard_button" mat-button
                matTooltip="Zum Dashboard"
                [routerLinkActiveOptions]="{'exact': true}">
          <svg-icon>dashboard</svg-icon>
        </button>

        <main-menu-button></main-menu-button>
      </td>
      <td class="td2">
        <div *ngIf="!(isMobile|async)">
          <div style="display: inline-block">
            <div [class.hidden]="(nbLabel|async) ? true:false">
              {{domainConfig.pagetitle}}
            </div>
            <div *ngIf="nbLabel|async">
              {{nbLabel|async}}
            </div>
          </div>

          <permission-block [oneOfRoles]="['Vertreter']">
            <div *ngIf="cfg.vertreterconfig.value && cfg.vertreterconfig.value.DomainVertreterShowLkndInHead" style="display: inline-block; margin-left: 50px">
              <a *ngIf="(currentLieferkunde|async)" style="font-size: 11px; line-height: 11px" class="tut_lieferkunde_button" routerLink="/lieferkunden" matTooltip="Lieferanschrift  wechseln"
                 [class.lieferkundeButtonHoverOpacity]="!!(nbBgLinkColor|async)"
                 [style.color]="(nbBgLinkColor|async) ? (nbBgLinkColor|async)+' !important' : ''"
              >
                <span *ngIf="domainConfig.show_customer_numbers">
                  <svg-icon verticalAlign="top">lieferadresse</svg-icon>
                  <div *ngIf="!(isMobile|async)" style="display: inline-block; text-align: left; padding-top: 1px; padding-left: 5px">
                    <ng-container *ngIf="domainConfig.showA2KndNrOverwrite == 'A2KndNrDefault'">
                      {{(currentLieferkunde|async)?.A2KndNrDefault}}
                    </ng-container>
                    <ng-container *ngIf="domainConfig.showA2KndNrOverwrite == 'A2KndNrOrganisationseinheit'">
                      {{(currentLieferkunde|async)?.A2KndNr}}
                    </ng-container>
                    <ng-container *ngIf="domainConfig.showA2KndNrOverwrite == 'Nein'">
                      {{(currentLieferkunde|async)?.LieferkundeNr}}
                    </ng-container>
                     - {{(currentLieferkunde|async)?.Vorname}} <br> {{(currentLieferkunde|async)?.Nachname}}
                    {{(currentLieferkunde|async)?.Ort}}</div>
                </span>
                          <span *ngIf="!domainConfig.show_customer_numbers">
                  <svg-icon verticalAlign="top">lieferadresse</svg-icon>
                  <div *ngIf="!(isMobile|async)" style="display: inline-block; text-align: left; padding-top: 1px; padding-left: 5px">
                    {{(currentLieferkunde|async)?.Vorname}} <br> {{(currentLieferkunde|async)?.Nachname}}
                    {{(currentLieferkunde|async)?.Ort}}</div>
                </span>
              </a>
            </div>
          </permission-block>
        </div>
      </td>
      <td class="td3">
        <div *ngIf="!(isMobile|async)" style="display: inline-block">
          <global-search-box></global-search-box>
          <permission-block [oneOfRoles]="['Auftrag']">
            <app-warenkorb-menu></app-warenkorb-menu>
          </permission-block>
          <app-notifications></app-notifications>

          <differential-lk-banner></differential-lk-banner>
          <app-user-menu></app-user-menu>
        </div>
        <div *ngIf="(isMobile|async)" style="display: inline-block">
          <permission-block [oneOfRoles]="['Auftrag']">
            <app-warenkorb-menu></app-warenkorb-menu>
          </permission-block>
          <app-notifications></app-notifications>
        </div>

        <div *ngIf="(isMobile|async)" style="display: inline-block;">

          <button (click)="showMenu2()" [class.active]="active2|async" class="" mat-button>
            <mat-icon *ngIf="!(active2|async)">expand_less</mat-icon>
            <mat-icon *ngIf="(active2|async)">expand_more</mat-icon>

          </button>
        </div>

      </td>
    </tr>
  </table>
</mat-toolbar>

<div *ngIf="(isMobile|async)" [class.hidden]="!(active2|async)" class="mainmenu2 mat-elevation-z1">
  <global-search-box></global-search-box>
  <br>
  <differential-lk-banner></differential-lk-banner>
  <app-user-menu></app-user-menu>
</div>
