<customer-service-watcher></customer-service-watcher>
<app-navbar></app-navbar>
<app-loader [class.printhidden]="psvc.printing|async"></app-loader>
<update-icon></update-icon>
<domain-suggestions></domain-suggestions>

<div class="sectioncontent" [class.mobile]="msvc.isMobile()|async">
  <ngx-simplebar style="height: 100%">
    <div class="sectioncontent-inner" [class.mobile]="msvc.isMobile()|async" [class.pagecontent]="fullheight|async">
      <app-main-menu [class.hidden]="(showType|async) != 'menu'"></app-main-menu>
      <app-dashboard-view [class.hidden]="(showType|async) != 'dashboard'"></app-dashboard-view>
      <router-outlet (activate)="activate($event)" [class.hidden]="(showType|async) != 'router'"></router-outlet>
    </div>
  </ngx-simplebar>
</div>
<div class="globalhelpbutton tut_global_helpbutton" [class.hidden]="!(show_help_button|async)" [class.mobile]="msvc.isMobile()|async">
  <contact-button></contact-button>
</div>
