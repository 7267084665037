import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';

export interface IPlatformService {
  readonly isBrowser: boolean;
  readonly isServer: boolean;
}

declare let document: any;

@Injectable({providedIn: 'root'})
export class AppPlatformService implements IPlatformService {
  last_scrolled: any;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
  }

  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  focusById(id: string): void {
    if (this.isBrowser) {
      setTimeout(() => {
        const elem = document.getElementById(id);
        this.focusElem(elem);
      }, 200);
    }
  }

  reScroll() {
    if (this.last_scrolled) {
      this.scrollElem(this.last_scrolled);
    }
  }

  scrollElem(elem: any): void {
    if (this.isBrowser) {
      setTimeout(() => {
        this.last_scrolled = elem;
        elem.scrollIntoView();
      }, 500);
    }
  }

  focusElem(elem: any): void {
    if (this.isBrowser) {
      setTimeout(() => {
        elem.focus();
        this.scrollElem(elem);
      }, 200);
    }
  }
}
